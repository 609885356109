.admin-body {
    background: #F5F7FA;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.admin {
    background: #ffffff;
    border-radius: 2px;
    height: auto;
}

.tittle {
    border-bottom: 1px solid #BABFC7;
    text-align: center;
    align-items: center;
    height: 50px;
    font-family: Montserrat, Georgia, 'Times New Roman', Times, serif;
}

.tittle h3 {
    font-weight: 500;
    letter-spacing: .05rem;
    font-size: 1.12rem;
    text-transform: uppercase;
    color: #404E67;
    font-family: Montserrat, Georgia, 'Times New Roman', Times, serif;
    line-height: 1.2;
}

.add {
    background: #2DCEE3;
    color: #FFF;
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1;
    border-radius: 0.21rem;
    border-color: #2DCEE3;
    text-transform: uppercase;
}

.add1 {
    color: #FFF;
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1;
    border-radius: 0.21rem;
    background: #16D39A;
    border-color: #16D39A;
}

.search-bar {
    border-radius: 4px;
    border: 1px solid #BABFC7;
    color: #4E5154;
    background-color: #FFF;
    background-clip: padding-box;
}

.table-bordered {
    border: 1px solid #98A4B8;
}

table {
    vertical-align: top;
}

.tabledata th {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.45;
}

.tablehead th {
    color: #404E67;
    font-weight: bold;
}

.brand-text {
    color: white;
    font-weight: 500;
    font-size: 18px;
    vertical-align: -webkit-baseline-middle;
}

.imgsrc {
    width: 45px;
    border-radius: 26px;
}

.tableimage {
    border-radius: 26px;
}

.addstudent {
    float: right;
}

img.rounded-circle.header-img {
    height: 45px;
    cursor: pointer;
}

i.fas.ri-menu-2-line.header-toogle {
    font-size: 26px;
}

.demo-wrapper {
    border: 1px solid #ced4da;
}

.file {
    visibility: hidden;
    position: absolute;
}

div#collapseOne {
    text-align: center;
}

div#collapseTwo {
    text-align: center;
}

.card-4 {
    cursor: pointer;
    color: #505d69;
    background: #BBC94D;
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}


/* .card-1 :hover {
    color: #505d69;
} */

.colorcard {
    font-weight: 600;
    color: white;
    position: absolute;
    top: 75px;
    left: 33px;
}

.card-3 {
    background: #FF884D;
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}

.card-2 {
    background: #EE4D4D;
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}

.card-1 {
    background: #5E94FF;
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}

.cards {
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}

.examimage {
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
}

p.errorText {
    color: red;
    /* font-weight: bold; */
}

.button {
    float: right;
    margin-top: 22px;
}

.popup {
    background: black;
}

.filtersubmit {
    font-weight: 400;
    border: 1px solid black;
}


/* .imgageupload {
    background: #c2c7d0;
} */

.validation,
.validation1 {
    color: red;
    position: absolute;
}

.validation {
    top: 373px;
    left: 22px;
    font-size: 16px;
    font-weight: bold;
}
.SuccessMessage{
    margin-left: 745px;
    font-weight: bold;
    font-size: 15px;
    color: #23af85
}

.validation1 {
    top: 365px;
}

.imagespace {
    height: 200px;
    background: #f5f7fa;
}

.adminimageview {
    height: 130px;
}

.CategoryImage{
    height: 77px;
    width: 88px;
}

.uploadprofile {
    height: 64px;
    border-radius: 35px;
    width: 65px;
}

/* .modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;

    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0; 
} */

.ErrorMessage{
    /* font-size: 10px; */
    font-weight: bold;
    color: #f93154;
}

.Questionimage {
    height: 50px;
    width: 50px;
}
.OptionsImage {
    height: 55px;
    width: 80px;
}

.optionimage{
    height:38px;
    width:56px;
}

.viewprofile {
    height: 250px;
    width: 330px;
}

.closeBtn {
    background: #1cbb8c;
    padding: 5px 8px 5px 8px;
    color: white;
    font-size: 13px;
    font-weight: 800;
    cursor: pointer;
}
.coloseButton {
    display: flex;
    align-content: flex-end;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.ReviewBtn {
    padding: 7px;
    font-weight: 700;
}

@media screen and (max-width:575px) {
    .imgageupload {
        margin-top: 20px;
    }
    .SuccessMessage{
        margin-left: 15px;
    }
    .tournamentimgageupload {
        margin-bottom: 20px;
    }
    .colorcard {
        font-weight: 600;
        color: white;
        position: absolute;
        top: 87px;
        left: 45px;
    }
}
@media screen and (max-width:1300px) {
    .SuccessMessage{
        margin-left: 15px;
    }
}

.uploadprofiletour {
    height: 100px;
    width: 150px;
}

.viewTournament {
    height: 300px;
    width: 500px;
}
.iconss {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.sizeofbanner{
    height: 370px;
}
.sendmoneybtn {
    margin-top: 25px;
}