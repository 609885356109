.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    /* width: 50%; */
    padding: 25px;
    border: 1px solid #d7d7d7;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 461px;
    /* height: 157px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    border-radius: 5px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}


/* [data-popup='tooltip'].popup-overlay {
    background: transparent;
} */

.popup-arrow {
    filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.filterbtn {
    border: 1px solid #ced4da;
}

.popupbtn {
    height: 120px;
    background: white;
    border: 1px dotted #ced4da;
    width: 177px;
}

.popupbtn2 {
    width: 150px;
    height: 102px;
    background: white;
    border: 1px dotted #ced4da;
}