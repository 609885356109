.examlabel {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted #ced4da;
    /* border-radius: 20px; */
    width: 20rem;
    height: 7rem;
    cursor: pointer;
    font-size: large;
}

.examlabel span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
}

.examinput {
    display: none;
}

img {
    padding: 0;
    margin: 0;
}

.images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.image {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.image button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
}

.image button:hover {
    background-color: red;
}

.image p {
    padding: 0 0.5rem;
    margin: 0;
}

.upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: green;
}

.error {
    text-align: center;
}

.error span {
    color: red;
}
.bannerImage{
    height:250px;
    width:90%;
    margin-bottom: 10px;
}